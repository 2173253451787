<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="panel">
          <div class="panel-heading bg-dark text-light">
            <h3 class="panel-title bold">Form Quick Count</h3>
          </div>
          <div class="panel-body">
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label>Kecamatan : </label>
                  <select
                    v-model="form.kec"
                    v-on:change="fetchKelurahan()"
                    class="form-control"
                    :class="{ 'is-invalid': formValidate.kec }"
                  >
                    <option value="">Pilih</option>
                    <option
                      v-for="kecamatan in listKecamatan"
                      :value="kecamatan.id_kec"
                      :key="kecamatan.id_kec"
                      :data-key="kecamatan.id_kec"
                    >
                      {{ kecamatan.nama_kecamatan }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label>Desa : </label>
                  <select
                    v-model="form.kel"
                    class="form-control"
                    :class="{ 'is-invalid': formValidate.kel }"
                  >
                    <option value="">Pilih</option>
                    <option
                      v-for="kelurahan in listKelurahan"
                      :value="kelurahan.id_kel"
                      :key="kelurahan.id_kel"
                    >
                      {{ kelurahan.nama_kelurahan }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="tps">TPS : </label>
                  <select
                    v-model="form.tps"
                    class="form-control"
                    name="tps"
                    placeholder="Nomor TPS"
                    required
                    :class="{ 'is-invalid': formValidate.tps }"
                  >
                    <option value="" disabled>Pilih Nomor TPS</option>
                    <option
                      v-for="tps in getTpsOptions()"
                      :key="tps"
                      :value="tps"
                    >
                      {{ tps }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="dpt">Jumlah DPT : </label>
                  <input
                    type="number"
                    v-model="form.dpt"
                    class="form-control"
                    name="dpt"
                    placeholder="Jumlah DPT"
                    required
                    :class="{ 'is-invalid': formValidate.dpt }"
                  />
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="suara_partai">Suara Partai : </label>
                  <input
                    type="number"
                    v-model="form.suara_partai"
                    class="form-control"
                    name="suara_partai"
                    placeholder="Suara Partai"
                    required
                    :class="{ 'is-invalid': formValidate.suara_partai }"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="caleg01">Suara Sutrianto, S.H : </label>
                  <input
                    type="number"
                    v-model="form.caleg01"
                    class="form-control"
                    name="caleg01"
                    placeholder="Suara Sutrianto, S.H"
                    required
                    :class="{ 'is-invalid': formValidate.caleg01 }"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="caleg02">Suara Ilham J.Lamahuseng : </label>
                  <input
                    type="number"
                    v-model="form.caleg02"
                    class="form-control"
                    name="caleg02"
                    placeholder="Suara Ilham J.Lamahuseng"
                    required
                    :class="{ 'is-invalid': formValidate.caleg02 }"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="caleg03"
                    >Suara Mohamad Arief Nugroho, S.Kep, Ns. :
                  </label>
                  <input
                    type="number"
                    v-model="form.caleg03"
                    class="form-control"
                    name="caleg03"
                    placeholder="Suara Mohamad Arief Nugroho, S.Kep, Ns."
                    required
                    :class="{ 'is-invalid': formValidate.caleg03 }"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="caleg04">Suara Fatma.M.Amin, S.H : </label>
                  <input
                    type="number"
                    v-model="form.caleg04"
                    class="form-control"
                    name="caleg04"
                    placeholder="Suara Fatma.M.Amin, S.H"
                    required
                    :class="{ 'is-invalid': formValidate.caleg04 }"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="caleg05">Suara Safriyanti Ridwan.Yt : </label>
                  <input
                    type="number"
                    v-model="form.caleg05"
                    class="form-control"
                    name="caleg05"
                    placeholder="Suara Safriyanti Ridwan.Yt"
                    required
                    :class="{ 'is-invalid': formValidate.caleg05 }"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="caleg06">Suara Andi Muchtar, S.T : </label>
                  <input
                    type="number"
                    v-model="form.caleg06"
                    class="form-control"
                    name="caleg06"
                    placeholder="Suara Andi Muchtar, S.T"
                    required
                    :class="{ 'is-invalid': formValidate.caleg06 }"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-md-3">
                <label>Upload Foto Berkas</label>
                <input
                  style="display: none"
                  ref="input"
                  type="file"
                  name="image"
                  accept="image/*"
                  @change="setImage"
                />
                <a
                  href="#"
                  class="btn btn-block btn-primary m-1"
                  role="button"
                  @click.prevent="showFileChooser"
                >
                  <i class="fa fa-fw fa-image"></i> Pilih/Ambil Foto Berkas
                </a>
                <div class="row">
                  <div class="col-md-12 cropper-area">
                    <div class="img-cropper">
                      <vue-cropper
                        ref="cropper"
                        :aspect-ratio="3 / 4"
                        :src="imgSrc"
                        preview=".preview"
                        style="
                          width: 100%;
                          border-radius: 5px;
                          overflow: hidden;
                        "
                      />
                      <div v-if="this.imgSrc">
                        <div class="btn-group mt-1">
                          <a
                            href="#"
                            class="btn btn-sm btn-primary"
                            role="button"
                            @click.prevent="rotate(90)"
                          >
                            <i class="fa fa-redo"></i>
                          </a>
                          <a
                            href="#"
                            class="btn btn-sm btn-primary"
                            role="button"
                            @click.prevent="rotate(-90)"
                          >
                            <i class="fa fa-redo fa-flip-horizontal"></i>
                          </a>
                          <a
                            href="#"
                            class="btn btn-sm btn-primary"
                            role="button"
                            @click.prevent="cropImage"
                          >
                            <i class="fa fa-crop"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-3" v-if="cropImg">
                <label>Hasil</label>
                <img
                  v-if="cropImg"
                  :src="cropImg"
                  alt="Cropped Image"
                  style="width: 100%; border-radius: 5px; overflow: hidden"
                />
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <button
              class="btn btn-success pull-right"
              @click="postData"
              variant="primary"
            >
              <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
              Submit
            </button>
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Kembali</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";
import moment from "moment";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  mixins: [sipData],
  components: {
    VueCropper,
  },
  data() {
    return {
      form: {
        id_quick_qount: "",
        kec: "",
        kel: "",
        tps: "TPS 01",
        dpt: "",
        suara_partai: "",
        caleg01: "",
        caleg02: "",
        caleg03: "",
        caleg04: "",
        caleg05: "",
        caleg06: "",
        tidak_sah: "",
      },
      loading: false,
      showUserAccess: true,
      formValidate: [],
      listKecamatan: [],
      listKelurahan: [],
      listDesa: [],
      listDPT: [],
      imgSrc: "",
      cropImg: "",
      notImage: false,
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    const id = this.$route.params.id;
    if (id) {
      this.fetchData(id);
    } else {
      this.detail.id = "";
    }
    this.fetchKecamatan();
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();

      if (this.cropImg.includes("http") || this.cropImg == "") {
        // formData.append("foto", "");
      } else {
        const blob = this.dataURLtoBlob(this.cropImg);
        formData.append("foto_berkas", blob, "foto.png");
      }

      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post("/v1/quickcount/update/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData(param) {
      axios
        .get("/v1/quickcount/detail/" + param)
        .then((response) => {
          for (let key in response.data.data) {
            this.form[key] = response.data.data[key];
          }
          this.fetchKelurahan();
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    showFileChooser() {
      this.$refs.input.click();
    },

    dataURLtoBlob(dataURL) {
      const base64Data = dataURL.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: "image/png" });
    },

    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },

    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        this.notImage = true;
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.notImage = false;
          this.imgSrc = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        this.notImage = true;
        alert("Sorry, FileReader API not supported");
      }
    },

    getTpsOptions() {
      return Array.from({ length: 15 }, (_, index) => {
        const tpsNumber = "TPS " + (index + 1).toString().padStart(2, "0");
        return tpsNumber;
      });
    },
    fetchKecamatan() {
      axios
        .get("/v1/daerah/kecamatan/7209", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.listKecamatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    fetchKelurahan() {
      if (this.form.kec) {
        axios
          .get("/v1/daerah/kelurahan/" + this.form.kec, {
            headers: {
              token: this.userToken,
            },
          })
          .then((response) => {
            this.listKelurahan = response.data.data;
            this.fetchData();
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
  },
};
</script>
